import "./Pilares.css";

import React, { useRef } from "react";
import useIntersection from "./useIntersection";
import { Trans } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

import { StaticImage } from "gatsby-plugin-image";

import Logo from "../../svg/begin-logo.svg";
import MaskImg0 from "../../svg/masks/maskImg0.svg";
import MaskImg1 from "../../svg/masks/maskImg1.svg";
import MaskImg2 from "../../svg/masks/maskImg2.svg";
import MaskImg3 from "../../svg/masks/maskImg3.svg";
import MaskImg4 from "../../svg/masks/maskImg4.svg";
import MaskImg5 from "../../svg/masks/maskImg5.svg";
import MaskImg6 from "../../svg/masks/maskImg6.svg";
import Arrow1 from "../../svg/flechas/arrow1.svg";
import Arrow2 from "../../svg/flechas/arrow2.svg";
import Arrow3 from "../../svg/flechas/arrow3.svg";
import Arrow4 from "../../svg/flechas/arrow4.svg";
// import Arrow5 from "../../svg/flechas/arrow5.svg"
import Arrow6 from "../../svg/flechas/arrow6.svg";
// import Arrow7 from "../../svg/flechas/arrow7.svg"
import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";

export default function Pilares() {
	const { t } = useI18next();
	const arrow1 = useRef();
	const arrow2 = useRef();
	const arrow3 = useRef();
	const arrow4 = useRef();
	// const arrow5 = useRef()
	// const arrow6 = useRef()
	// const arrow7 = useRef()

	const arrow1Visible = useIntersection(arrow1);
	const arrow2Visible = useIntersection(arrow2);
	const arrow3Visible = useIntersection(arrow3);
	const arrow4Visible = useIntersection(arrow4);
	// const arrow5Visible = useIntersection(arrow5)
	// const arrow6Visible = useIntersection(arrow6)
	// const arrow7Visible = useIntersection(arrow7)

	return (
		<div id="pilares" className="section invertColors">
			<MaskImg0 />
			<MaskImg1 />
			<MaskImg2 />
			<MaskImg3 />
			<MaskImg4 />
			<MaskImg5 />
			<MaskImg6 />
			<div>
				<div className="cartaSectionTitle">
					<Logo id="compromisoLogo" />
					<div>
						<h1>
							<Trans>Proyecto génesis</Trans>
						</h1>
					</div>
					<h3 className="juana">
						<Trans>Nuestros valores</Trans>
					</h3>
				</div>

				<StaticImage
					className="pilaresBgImg bg1"
					src="../../images/valores/bg1.png"
					alt=""
				/>
				<StaticImage
					className="pilaresBgImg bg2"
					src="../../images/valores/bg2.png"
					alt=""
				/>
				<StaticImage
					className="pilaresBgImg bg3"
					src="../../images/valores/bg3.png"
					alt=""
				/>
				<StaticImage
					className="pilaresBgImg bg4"
					src="../../images/valores/bg4.png"
					alt=""
				/>

				<div className="valoresGrid">
					<div id="p1" className="pilar">
						<span className="pilarNum">1</span>
						<a href="/pdf.pdf" className="valorImgLink pdfLink">
							<StaticImage
								className="valorImg"
								src="../../images/valores/uniformes.jpg"
								alt="Valor Image"
							/>
						</a>
						<a
							href="/pdf.pdf"
							className="pdfLink"
							aria-label={t("Uniformes sostenibles")}
						>
							<h2>
								<Trans i18nKey="pilarTitleUniformes">
									Uniformes
									<br /> sostenibles
								</Trans>
							</h2>
						</a>

						<br />
						<p>
							<Trans>
								La ropa que llevan nuestros compañeros está desarrollada por Top
								Manta, una iniciativa comprometida con la solidaridad y los
								derechos humanos, ayudando a personas en riesgo de exclusión.
								Todo esto lo hacen con materiales reciclados y sostenibles,
								además de procesos artesanales.
							</Trans>
						</p>
						<br />
					</div>

					<div id="p2" className="pilar">
						<div
							ref={arrow1}
							className={`${arrow1Visible ? "arrowAppear" : ""}`}
						>
							<Arrow1 id="arrow1" />
						</div>
						<a
							href="/pdf.pdf"
							className="valorImgLink pdfLink"
							aria-label={t("Vinos ecológicos y veganos")}
						>
							<StaticImage
								className="valorImg"
								src="../../images/valores/vinos.jpg"
								alt="Valor Image"
							/>
						</a>
						<span className="pilarNum">2</span>
						<a
							href="/pdf.pdf"
							className="pdfLink"
							aria-label={t("Vinos ecológicos y veganos")}
						>
							<h2>
								<Trans i18nKey="pilarTitleVinos">
									Vinos ecológic<span className="imgOverlay h2">os</span>
									<br />y vegano<span className="imgOverlay h2">s</span>
								</Trans>
							</h2>
						</a>

						<p>
							<Trans>
								Casi la totalidad de nuestros vinos son veganos y ecológicos y
								provenientes de productores locales.
							</Trans>
						</p>
					</div>

					<div id="p3" className="pilar">
						<div
							ref={arrow2}
							className={`${arrow2Visible ? "arrowAppear" : ""}`}
						>
							<Arrow2 id="arrow2" />
						</div>
						<a href="/pdf.pdf" className="valorImgLink pdfLink">
							<StaticImage
								className="valorImg"
								src="../../images/valores/salud.jpg"
								alt="Valor Image"
							/>
						</a>
						<a href="/pdf.pdf" className="pdfLink" aria-label={t("Salud")}>
							<h2>
								<Trans>Salud</Trans>
							</h2>
						</a>

						<span className="pilarNum">3</span>
						<p>
							<Trans>
								Comprobamos que los platos forman parte de una dieta saludable y
								equilibrada. Para ello hemos trabajado mano a mano con nuestro
								nutricionista y con proveedores que nos proporcionan
								ingredientes naturales.
							</Trans>
						</p>
					</div>

					<div id="p4" className="pilar">
						<div
							ref={arrow3}
							className={`${arrow3Visible ? "arrowAppear" : ""}`}
						>
							<Arrow3 id="arrow3" />
							<Arrow6 className="arrowMb arrow" id="arrow6" />
						</div>
						<a
							href="/pdf.pdf"
							className="valorImgLink pdfLink"
							aria-label={t("Vajilla artesana")}
						>
							<StaticImage
								className="valorImg"
								src="../../images/valores/vajilla.jpg"
								alt="Valor Image"
							/>
						</a>
						<span className="pilarNum">4</span>
						<a
							href="/pdf.pdf"
							className="pdfLink"
							aria-label={t("Vajilla artesana")}
						>
							<h2>
								<Trans>
									Vajilla <br className="hideDsktp" /> artesana
								</Trans>
							</h2>
						</a>

						<p>
							<Trans>
								Casi la totalidad de nuestra vajilla está hecha a mano. Los
								platos nos los han hecho personalizados desde Almería y
								Alicante, y los vasos los han realizado artesanos que los han
								fabricado con el mayor cuidado y detalle.
							</Trans>
						</p>
					</div>

					<div id="p5" className="pilar">
						<div
							ref={arrow4}
							className={`${arrow4Visible ? "arrowAppear" : ""}`}
						>
							<Arrow4 id="arrow4" />
						</div>
						<a
							href="/pdf.pdf"
							className="valorImgLink pdfLink"
							aria-label={t("Construcción artesanal")}
						>
							<StaticImage
								className="valorImg"
								src="../../images/valores/construccion.jpg"
								alt="Valor Image"
							/>
						</a>
						<span className="pilarNum">5</span>
						<a
							href="/pdf.pdf"
							className="pdfLink"
							aria-label={t("Construcción artesanal")}
						>
							<h2>
								<Trans i18nKey="pilarTitleConstrucción">
									Construcción
									<br />
									artesanal
								</Trans>
							</h2>
						</a>

						<p>
							<Trans>
								Para la construcción de nuestro local nos hemos puesto en
								contacto con proveedores cercanos, además un maestro fallero se
								ha encargado de la construcción de la estructura.
							</Trans>
						</p>
					</div>

					{/* <div id="p6" className="pilar">
            <div
              ref={arrow5}
              className={`${arrow5Visible ? "arrowAppear" : ""}`}
            >
              <Arrow5 id="arrow5" />
            </div>
            <span className="pilarNum">6</span>
            <a href="/pdf.pdf" className="valorImgLink pdfLink">
              <StaticImage
                className="valorImg"
                src="../../images/valores/equipo.jpg"
                alt="Valor Image"
              />
            </a>
            <a href="/pdf.pdf" className="pdfLink">
              <h2>
                <Trans>Equipo</Trans>
              </h2>
            </a>

            <br />
            <p>
              <Trans>
                Todos los miembros de nuestro equipo, tanto cargos directivos
                como personal de oficina, antes de empezar en nuestro puesto
                trabajamos durante 3 días en cocina y/o servicio en el local
                para empatizar y comprender el trabajo de nuestros compañer@s.
              </Trans>
            </p>
            <br />
          </div> */}

					{/* <div id="salarios" className="pilar">
            <div
              ref={arrow6}
              className={`${arrow6Visible ? "arrowAppear" : ""}`}
            >
              <Arrow6 id="arrow6" />
            </div>
            <span className="pilarNum">7</span>
            <a href="/pdf.pdf" className="valorImgLink pdfLink">
              <StaticImage
                className="valorImg"
                src="../../images/valores/tributacio.jpg"
                alt="Valor Image"
              />
            </a>
            <a href="/pdf.pdf" className="pdfLink">
              <h2>
                <Trans>Salarios</Trans>
              </h2>
            </a>

            <br />
            <p>
              <Trans>
                Pollo de corral estilo marroquí con curry, arroz integral y
                berenjena. Procedente de una pequeña granja de Teruel.
              </Trans>
            </p>
            <br />
          </div>

          <div id="tributacion" className="pilar">
            <div
              ref={arrow7}
              className={`${arrow7Visible ? "arrowAppear" : ""}`}
            >
              <Arrow7 id="arrow7" />
            </div>
            <a href="/pdf.pdf" className="valorImgLink pdfLink">
              <StaticImage
                className="valorImg"
                src="../../images/valores/salarios.jpg"
                alt="Valor Image"
              />
            </a>
            <span className="pilarNum">8</span>
            <a href="/pdf.pdf" className="pdfLink">
              <h2>
                <Trans>Tributación nacional</Trans>
              </h2>
            </a>

            <p>
              <Trans>
                Pollo de corral estilo marroquí con curry, arroz integral y
                berenjena. Procedente de una pequeña granja de Teruel.
              </Trans>
            </p>
          </div> */}
				</div>
			</div>
			<FlorituraHoja className="footerFloritura" />
		</div>
	);
}
